import KML from 'ol/format/KML.js';
import Feature from 'ol/Feature.js';
import Geolocation from 'ol/Geolocation.js';
import Map from 'ol/Map.js';
import Point from 'ol/geom/Point.js';
import View from 'ol/View.js';
import {Circle as CircleStyle, Icon, Fill, Stroke, Style} from 'ol/style.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';

const view = new View({
  center: [56.3574, 43.9467],
  zoom: 15,
});

const vector = new VectorLayer({
  source: new VectorSource({
    url: 'maps.kml',
    format: new KML(),
  }),
});

const map = new Map({
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
    vector
  ],
  target: 'map',
  view: view,
});


const geolocation = new Geolocation({
  // enableHighAccuracy must be set to true to have the heading value.
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: view.getProjection(),
});

function el(id) {
  return document.getElementById(id);
}

var counter = 0;

/*el('track').addEventListener('change', function () {
  geolocation.setTracking(this.checked);
});*/

// update the HTML page when the position changes.
geolocation.on('change', function () {
  //el('accuracy').innerText = Math.ceil(geolocation.getAccuracy()) + ' м';
  //el('heading').innerText = geolocation.getHeading() + ' [рад 2]';

  var speed = geolocation.getSpeed();
  if( speed == undefined ) speed = 0;
  if( speed >= 0 )
  {
    speed = Math.ceil(speed * 3.6 * 1.065);
    el('info').innerText = speed + ' км/ч';
  }
});

// handle geolocation error.
geolocation.on('error', function (error) {
  const info = document.getElementById('info');
  info.innerHTML = error.message;
  //info.style.display = '';
});

const accuracyFeature = new Feature();
geolocation.on('change:accuracyGeometry', function () {
  accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
});


var boatIcon = new Style({
  image: new Icon({
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: 'data/boat.png',
    rotation: 0.04,
    scale: 0.25
  }),
});

let roundIcon = new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#ffffff',
      }),
      stroke: new Stroke({
        color: '#000000',
        width: 4,
      }),
    }),
  });


const positionFeature = new Feature();
positionFeature.setStyle( roundIcon );


var lastCenter = undefined;

geolocation.on('change:position', function () {
  const coordinates = geolocation.getPosition();
  if ( coordinates )
  {
     positionFeature.setGeometry(new Point(coordinates));

     map.setView(new View({
            center: coordinates,
            zoom: map.getView().getZoom(), //15,
            rotation: map.getView().getRotation() }));


     /*let heading = geolocation.getHeading();
     if( heading == undefined ) heading = 0;
     if( heading ) boatIcon.image.roation = heading;

     let center = map.getView().getCenter();
     
     if( lastCenter && center &&
         (lastCenter[0] != center[0] || 
          lastCenter[1] != center[1]))
     {
        counter = 10;
     } 
     else
     {
        if(counter > 0) 
        { 
           counter = counter - 1;
        }
        else
        {
          map.setView(new View({
            center: coordinates,
            zoom: map.getView().getZoom(), //15,
            rotation: map.getView().getRotation() }));
        }
     }
     lastCenter = map.getView().getCenter();         */
 }
});

new VectorLayer({
  map: map,
  source: new VectorSource({
    features: [accuracyFeature, positionFeature],
  }),
});

geolocation.setTracking(true);
